<template>
  <v-app light>
    <!-- <v-toolbar color="white">
      <v-toolbar-title v-text="title"></v-toolbar-title>
    </v-toolbar>-->
    <div>
      <v-container grid-list-xl pa-0 pb-4>
        <v-layout row wrap>
          <!-- Main Property Results -->
          <v-flex xs12 class="main-content">
            <span class="title-details">
              <v-icon class="mr-1">mdi-hotel</v-icon>
              {{currentProperty.count_bedrooms }}
            </span>
            <span class="title-details">
              <v-icon class="mr-1">mdi-shower</v-icon>
              {{currentProperty.count_bathrooms }}
            </span>
            <span class="title-details">
              <v-icon class="mr-1">mdi-aspect_ratio</v-icon>
              {{currentProperty.constructed_area }}m²
            </span>
            <span class="title-details r-price-details">
              <!-- <span v-if="isPromotion">{{$ft("client_shared.fieldLabels.from") }}</span> -->
              <DisplayPriceLegacy
                :symbolLast="false"
                :noCents="true"
                sourceCurrency="EUR"
                displayCurrency="EUR"
                :value="currentProperty.price_sale_current_cents * 100"
              ></DisplayPriceLegacy>
            </span>
            <!-- <v-btn
              color="#9acd32"
              :href="printUrl"
              target="_blank"
              class="white--text printbutton title-details t-right"
            >
              {{$ft("client_shared.labels.print") }}
              <v-icon right dark>print</v-icon>
            </v-btn>-->
            <!-- <v-btn
              color="#9acd32"
              @click="goToFavourites"
              v-if="favourited"
              class="white--text favebutton title-details t-right fav"
            >
              {{ $ft('client_shared.core.saved') }}
              <v-icon right dark>star</v-icon>
            </v-btn>
            <v-btn
              @click="addToFavourites"
              color="#9acd32"
              v-else
              class="white--text favebutton title-details t-right fav"
            >
              {{ $ft('client_shared.core.save') }}
              <v-icon right dark>star_border</v-icon>
            </v-btn>-->
          </v-flex>
          <v-flex xs12>
            <v-carousel>
              <v-carousel-item
                v-for="(item,i) in lightboxImages"
                :key="i"
                :src="item.src"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
          </v-flex>
          <v-flex>
            <v-card class="inmo-card px-1 py-5 mb-5">
              <v-card-title primary-title>
                <span class="headline">{{ $ft('client_shared.fieldLabels.description')}}</span>
              </v-card-title>
              <v-card-text>
                <div class>
                  <span v-html="currentProperty.description"></span>
                </div>
                <!-- <v-flex v-if="showMoreBtnVisible" pt-2 pb-0 xs12>
                  <v-btn
                    class="more-filters-button white--text"
                    large
                    depressed
                    block
                    color="#9acd32"
                    @click="toggleFilters()"
                  >{{ moreFiltersText }}</v-btn>
                </v-flex>-->
              </v-card-text>
              <v-card-actions></v-card-actions>
            </v-card>
            <ListingDetailsTable :isPromotion="false" :currentProperty="currentProperty"></ListingDetailsTable>
            <v-card class="inmo-card px-0 py-3">
              <v-card-title primary-title class="pl-5">
                <span class="headline">{{ $ft('client_shared.labels.propertyLocation')}}</span>
              </v-card-title>
              <MapIframe v-if="currentProperty.city" :location="currentProperty.city"></MapIframe>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </v-app>
</template>
<script>
import MapIframe from "@jsroot/apps/booster-pages/parts/MapIframe"
import ListingDetailsTable from "@jsroot/apps/booster-pages/parts/ListingDetailsTable"
import DisplayPriceLegacy from "@jsroot/apps/booster-pages/parts/DisplayPriceLegacy"
export default {
  components: {
    MapIframe,
    DisplayPriceLegacy,
    ListingDetailsTable
    // HalfShadeLeft
  },
  computed: {
    lightboxImages() {
      var lightboxImages = []
      var imageDetails = this.currentProperty.all_image_urls || []
      // if (
      //   this.currentProperty.Pictures &&
      //   this.currentProperty.Pictures.Picture
      // ) {
      //   imageDetails = this.currentProperty.Pictures.Picture
      // }
      if (imageDetails.length > 1) {
        imageDetails.forEach(function(imageDetail, index) {
          lightboxImages.push({
            thumb: imageDetail,
            src: imageDetail,
            alt_text: ""
          })
        })
        // return lightboxImages.slice(0, this.countImagesToShow)
        lightboxImages.push({})
        lightboxImages.push({})
        lightboxImages.push({})
        lightboxImages.push({})
        // above needed to fix issue with last imagesnot being clickable
        return lightboxImages
      } else {
        return []
      }
    }
  },
  data() {
    return {
      currentProperty: {},
      // title: "Welcome",
      // show: false,
      // showText: false
    }
  },
  mounted() {
    if (INMOAPP.bsd && INMOAPP.bsd.sale_listing) {
      this.currentProperty = INMOAPP.bsd.sale_listing
    }
  },
  methods: {
  }
}
</script>
<style>
</style>
// This exists just to wire up showMoreInfoModal
<template>
  <v-app>
    <v-container pa-5>
      <v-layout row>
        <v-flex xs12>
          <v-row justify="center">
            <!-- <v-btn color="primary" dark @click.stop="showAddListingModal = true">...Add listing</v-btn>
            <v-dialog v-model="showAddListingModal" max-width="290">
              <AddListingForm :boosterPage="boosterPage"></AddListingForm>
            </v-dialog>-->
            <MoreInfoModal
              :formSuccess="formSuccess"
              :formSending="formSending"
              :formErrors="formErrors"
              @runRequestInfo="runRequestInfo"
              v-bind:showModalProp.sync="showMoreInfoModal"
            ></MoreInfoModal>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import apiService from "@jsroot/services/api-public"
import MoreInfoModal from "@jsroot/apps/booster-pages/modal-forms/more-info"
import { formsHelper } from "@jsroot/mixins/forms-helper"
export default {
  mixins: [formsHelper],
  components: { MoreInfoModal },
  methods: {
    runRequestInfo(moreInfoParams) {
      this.formSending = true
      moreInfoParams.listing = this.activeListing
      let apiUrl = "/api_public/v1/forms/request_property_info_zoho"
      apiService()
        .put(apiUrl, moreInfoParams)
        .then(
          response => {
            this.formSending = false
            this.formSuccess = "Thank you.  We will contact you shortly."
          },
          err => {
            this.formSending = false
            if (err.response.data.error_messages) {
              this.formErrors = [err.response.data.error_messages]
            } else {
              this.formErrors = [err]
            }
            console.log(err)
          }
        )
    },
    startRequestMoreInfo(listingRef, listingUrl) {
      this.activeListing.ref = listingRef
      this.activeListing.url = listingUrl
      this.activeListing.listing_model = "sale_listing"
      this.showMoreInfoModal = true
    }
  },
  mounted() {
    // if (INMOAPP.bsd && INMOAPP.bsd.booster_page) {
    //   this.boosterPage = INMOAPP.bsd.booster_page
    // }
  },
  created() {
    // this.$parent.$data.bus.$on("more-info", this.startRequestMoreInfo)
    // had I passed the eventbus as data on the root element
    // would have needed above syntax
    this.$eventHub.$on("more-info", this.startRequestMoreInfo)
  },
  beforeDestroy() {
    this.$eventHub.$off("more-info")
  },
  data: function() {
    return {
      // boosterPage: {},
      activeListing: {},
      showMoreInfoModal: false
      // showAddListingModal: false
    }
  }
}
</script>
<style scoped>
/* p {
  font-size: 2em;
  text-align: center;
} */
</style>

<template>
  <v-form
    class="mt-2"
    v-model="formValid"
    ref="enqForm"
    lazy-validation
    @submit.prevent="onSubmitEnquiry"
  >
    <div class="py-4 title">Find out more about this outstanding property!</div>
    <v-select
      class="property-contact"
      solo
      :items="askTypes"
      :label="$ft('client_shared.forms.reqInfo')"
      v-model="moreInfoParams.contact['ask_type']"
      required
    ></v-select>
    <div v-for="(field) in propertyEnquiryShortFields" :key="field.fieldName" class="pt-0 pb-0">
      <v-text-field
        class="property-contact"
        solo
        :required="field.required"
        :rules="field.validationRules"
        name
        :label="$ft(field.labelTextTKey)"
        v-model="moreInfoParams.contact[field.fieldName]"
      ></v-text-field>
    </div>
    <v-checkbox
      v-model="moreInfoParams.alertAgreed"
      :label="$ft('client_shared.forms.receiveSimilarProps')"
      required
    ></v-checkbox>
    <!-- <v-checkbox class="mt-0" :required="true" label v-model="tandcAccepted">
      <span slot="label">
        {{ $ft('client_shared.gdpr.tAndCAcceptance') }}
        <a
          class="maincolor"
          href="/privacyTandCPage"
        >{{$ft("client_shared.gdpr.tAndC") }}</a>.
      </span>
    </v-checkbox>-->
    <p v-if="formErrors.length">
      <template v-for="(error,index) in formErrors">
        <v-alert dark :key="index" outlined color="error" icon="warning" :value="true">{{error}}</v-alert>
      </template>
    </p>
    <p v-if="formSuccess.length">
      <v-alert dark outlined color="success" dismissible v-model="successModel">{{ formSuccess }}</v-alert>
    </p>
    <v-flex class="pa-0" xs12 sm12>
      <template v-if="formSending">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </template>
      <v-btn
        v-else
        block
        height="54"
        type="submit"
        color="#9acd32"
        dark
      >{{ $ft("client_shared.forms.submit") }}</v-btn>
    </v-flex>
  </v-form>
</template>
<script>
export default {
  components: {},
  props: {
    formErrors: {
      type: Array,
      default: () => []
    },
    formSending: {
      type: Boolean,
      default: () => false
    },
    formSuccess: {
      type: String,
      default: () => ""
    }
  },
  beforeDestroy() {
    // this.$store.commit("clearPropertyEnquiryStatus", {})
  },
  data() {
    return {
      checkboxErrors: "",
      // ask_types: ["Ask for more information", "Ask for a visit", "Ask to be called back",
      //   "Ask for more photos"
      // ],
      promptTandC: false,
      tandcAccepted: true,
      successModel: true,
      // above only needed so success alert can be dismissed
      formValid: false,
      propertyEnquiryShortFields: [
        {
          labelTextTKey: "client_shared.core.name",
          fieldName: "name",
          inputType: "text",
          validationRules: [v => !!v || "Name is required"]
        },
        {
          labelTextTKey: "client_shared.core.email",
          fieldName: "email",
          inputType: "text",
          required: true,
          validationRules: [
            v => !!v || "E-mail is required",
            v =>
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "E-mail must be valid"
          ]
        },
        {
          labelTextTKey: "client_shared.core.tel",
          fieldName: "tel",
          inputType: "text",
          validationRules: []
        }
      ],
      moreInfoParams: {
        contact: {
          pref_lang: "English",
          ask_type: "Request a visit",
          name: "",
          message: ""
        },
        listing: {},
        alertAgreed: false,
        alert_details: {}
      }
    }
  },
  computed: {
    askTypes() {
      return [
        "Ask for more information",
        "Request a visit",
        "Request a phone call",
        "Ask for more photos"
        // this.$i18n.t("client_shared.forms.askInfo"),
        // this.$i18n.t("client_shared.forms.askVisit"),
        // this.$i18n.t("client_shared.forms.askCall"),
        // this.$i18n.t("client_shared.forms.askPhoto")
      ]
    }
  },
  watch: {
    tandcAccepted(newValue, oldValue) {
      if (newValue === true) {
        this.promptTandC = false
      }
    }
  },
  methods: {
    onSubmitEnquiry() {
      // if (!this.tandcAccepted) {
      //   this.promptTandC = true
      //   return
      // }

      // this.moreInfoParams.listing.listing_ref = this.propId
      // this.moreInfoParams.listing.listing_model_name = this.listingModelName

      if (this.moreInfoParams.alertAgreed) {
        this.moreInfoParams.contact.message =
          "I would like to recieve info about similar properties"
        // this.moreInfoParams.alert_details = this.alertDetails
      }
      this.formValid = this.$refs.enqForm.validate()
      // in case nothing has been typed in, above will display error messages
      // dec 2018 - previously just called validate() in function below
      // (like in ContactUsForm) but it seems that somehow formValid gets
      // set to true when an invalid submit is made
      if (!this.formValid) {
        return
      }
      this.$emit("runRequestInfo", this.moreInfoParams)

      // this.moreInfoParams.api_url =
      //   "/api_public/v3/forms/request_property_info_zoho"
      // this.$store.dispatch("sendEnquiry", this.moreInfoParams)
    }
  }
}
</script>
<template>
  <v-dialog max-width="390" v-model="showModal">
    <!-- <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="purple--text title-details">
        New Lead Page
        <v-icon right dark>mdi-plus-box</v-icon>
      </v-btn>
    </template>-->
    <v-card class="inmo-card px-0 py-0">
      <v-card-text>
        <v-layout wrap row>
          <v-flex text-xs-center xs12 class="mt-5" v-if="formSuccess.length">
            <v-alert @input="reloadLocation" outlined color="success">{{ formSuccess }}</v-alert>
            <v-btn
              block
              height="36"
              type="submit"
              color="#9acd32"
              dark
              @click="closeModal"
            >Ok</v-btn>
          </v-flex>
          <v-flex v-else text-xs-center xs12>
            <v-flex xs12 sm12 offset-sm0>
              <template v-if="formSending">
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </template>
            </v-flex>
            <NarrowEnquiryForm
              :formSuccess="formSuccess"
              :formSending="formSending"
              :formErrors="formErrors"
              @runRequestInfo="runRequestInfo"
            ></NarrowEnquiryForm>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap row>
        </v-layout>-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style>
</style>
<script>
import NarrowEnquiryForm from "@jsroot/apps/booster-pages/forms/NarrowEnquiryForm"
export default {
  components: {
    NarrowEnquiryForm
  },
  props: {
    showModalProp: {
      type: Boolean
    },
    formErrors: {
      type: Array,
      default: () => []
    },
    formSending: {
      type: Boolean,
      default: () => false
    },
    formSuccess: {
      type: String,
      default: () => ""
    }
  },
  // mounted: function() {  },
  data() {
    return {
      // formValid: false,
      // showModal: false,
      // newPageParams: {},
      // validationRules: [
      //   v => !!v || "Title for page is required"
      // ]
    }
  },
  watch: {},
  computed: {
    showModal: {
      // getter
      get: function() {
        return this.showModalProp
      },
      // setter
      set: function(newValue) {
        this.$emit("update:showModalProp", newValue)
      }
    }
  },
  methods: {
    runRequestInfo(moreInfoParams) {
      this.$emit("runRequestInfo", moreInfoParams)
    },
    closeModal() {
      // this.showModalProp = false
      this.$emit("update:showModalProp", false)
      // this.$emit("cleanupAddListing")
    },
    reloadLocation() {
      location.reload()
    }
    // modalActivated() {},
    // closeModal() {
    //   this.showModalProp = false
    //   // this.$emit("hideCurrentModal")
    // }
  }
}
</script>
<style>
</style>
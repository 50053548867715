<template>
  <v-app light>
    <v-container fluid pt-5>
      <v-layout row>
        <v-flex xs12>
          <MainLanding></MainLanding>
          <div class="scroll-view" :offset="400">
            <template slot-scope="visibility"></template>

            <!-- <Features :visible="true" key="features"></Features> -->
            <!-- <RowAnimation :visible="true" key="row"></RowAnimation> -->
            <!-- <ContactForm :visible="true" key="split"></ContactForm> -->
            <HalfShade :visible="true" key="col"></HalfShade>
            <PricingTable></PricingTable>
            <HalfShadeLeft :visible="true" key="hsl"></HalfShadeLeft>
          </div>
          <ContactForm></ContactForm>
        </v-flex>
        <v-flex xs12>
          <v-row justify="center" class="pa-5 ma-5"></v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <v-toolbar color="white">
      <v-toolbar-title v-text="title"></v-toolbar-title>
    </v-toolbar>
    <v-content>
      <section>
        <v-parallax src="/images/terraced-houses-2.jpeg" height="900">
          <v-layout column align-center justify-center class="darky-bg white--text">
            <div class="content" style="padding: 0px;">
              <v-layout class="hidden-md-and-down" style="padding-top: 12%;" wrap>
                <v-flex class="pr-5 mt-5 mb-5" xs12 sm12 md6>
                  <header class="white--text">
                    <h2 class="display-2 text-sm-right">PropertySquares</h2>
                    <transition name="slide-fade">
                      <p v-if="showText" class="headline text-sm-right">Beautiful property listings</p>
                    </transition>
                    <transition name="slide-in">
                      <p
                        class="text-sm-right"
                        v-if="showText"
                      >The smartest way to create an amazing real estate website!</p>
                    </transition>
                  </header>
                </v-flex>
                <v-flex class="mt-5 mb-5" xs12 sm12 md6>
                  <span class="image" style>
                    <transition name="bounce">
                      <img v-if="show" src="/images/rerenting-landing_framed.png" alt />
                    </transition>
                  </span>
                </v-flex>
              </v-layout>
              <v-layout class="hidden-lg-and-up" style="padding-top: 2%;" wrap>
                <v-flex class="mt-5 mb-5" xs12 sm12 md6>
                  <header class="white--text">
                    <h2 class="display-2 text-sm-left">PropertySquares</h2>
                    <transition name="slide-fade">
                      <p v-if="showText" class="headline text-sm-left">Beautiful property listings</p>
                    </transition>
                    <transition name="slide-in">
                      <p v-if="showText">The smartest way to create an amazing real estate website!</p>
                    </transition>
                  </header>
                </v-flex>
                <v-flex class="mt-5 mb-5" align-content-center xs12 sm12 md6>
                  <transition name="bounce">
                    <span v-if="show" class="image" style="height: 49%;width: 100%;">
                      <img src="/images/rerenting-landing_framed.png" alt />
                    </span>
                  </transition>
                </v-flex>
              </v-layout>
            </div>
            <!-- <img src="assets/vuetify.png" alt="Vuetify.js" height="200"> -->
            <h1 class="white--text mb-2 display-1 text-center">Parallax Template</h1>
            <div class="subheading mb-4 text-center">Powered by Vuetify</div>
            <v-btn
              class="mt-12"
              color="blue lighten-2"
              dark
              large
              href="/pre-made-themes"
            >Get Started</v-btn>
          </v-layout>
        </v-parallax>
      </section>

      <section>
        <v-layout column wrap class="my-12" align-center>
          <v-flex xs12 sm4 class="my-4">
            <div class="text-center">
              <h2 class="headline">The best way to start developing</h2>
              <span class="subheading">Cras facilisis mi vitae nunc</span>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md4>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large class="blue--text text--lighten-2">mdi-palette</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-center">Material Design</div>
                    </v-card-title>
                    <v-card-text>
                      Cras facilisis mi vitae nunc lobortis pharetra. Nulla volutpat tincidunt ornare.
                      Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                      Nullam in aliquet odio. Aliquam eu est vitae tellus bibendum tincidunt. Suspendisse potenti.
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large class="blue--text text--lighten-2">mdi-flash</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">Fast development</div>
                    </v-card-title>
                    <v-card-text>
                      Cras facilisis mi vitae nunc lobortis pharetra. Nulla volutpat tincidunt ornare.
                      Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                      Nullam in aliquet odio. Aliquam eu est vitae tellus bibendum tincidunt. Suspendisse potenti.
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card flat class="transparent">
                    <v-card-text class="text-center">
                      <v-icon x-large class="blue--text text--lighten-2">mdi-wrench</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-center">Completely Open Sourced</div>
                    </v-card-title>
                    <v-card-text>
                      Cras facilisis mi vitae nunc lobortis pharetra. Nulla volutpat tincidunt ornare.
                      Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                      Nullam in aliquet odio. Aliquam eu est vitae tellus bibendum tincidunt. Suspendisse potenti.
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>

      <section>
        <v-parallax src="/images/terraced-houses-2.jpeg" height="380">
          <v-layout column align-center justify-center>
            <div class="headline white--text mb-4 text-center">Web development has never been easier</div>
            <em>Kick-start your application today</em>
            <v-btn
              class="mt-12"
              color="blue lighten-2"
              dark
              large
              href="/pre-made-themes"
            >Get Started</v-btn>
          </v-layout>
        </v-parallax>
      </section>

      <section>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-12">
            <v-flex xs12 sm4>
              <v-card flat class="transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Company info</div>
                </v-card-title>
                <v-card-text>
                  Cras facilisis mi vitae nunc lobortis pharetra. Nulla volutpat tincidunt ornare.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                  Nullam in aliquet odio. Aliquam eu est vitae tellus bibendum tincidunt. Suspendisse potenti.
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm4 offset-sm1>
              <v-card flat class="transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Contact us</div>
                </v-card-title>
                <v-card-text>Cras facilisis mi vitae nunc lobortis pharetra. Nulla volutpat tincidunt ornare.</v-card-text>
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="blue--text text--lighten-2">mdi-phone</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>777-867-5309</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="blue--text text--lighten-2">mdi-map-marker</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Chicago, US</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="blue--text text--lighten-2">mdi-email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>john@vuetifyjs.com</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>

      <v-footer color="blue darken-2">
        <v-layout row wrap align-center>
          <v-flex xs12>
            <div class="white--text ml-4">
              Made with
              <v-icon class="red--text">mdi-heart</v-icon>by
              <a class="white--text" href="https://vuetifyjs.com" target="_blank">Vuetify</a>
              and
              <a class="white--text" href="https://github.com/vwxyzjn">Costa Huang</a>
            </div>
          </v-flex>
        </v-layout>
      </v-footer>
    </v-content>
  </v-app>
</template>
<script>
import MainLanding from "@jsroot/apps/weebrix/parts/MainLanding"
import HalfShade from "@jsroot/apps/weebrix/parts/HalfShade"
import HalfShadeLeft from "@jsroot/apps/weebrix/parts/HalfShadeLeft"
import ContactForm from "@jsroot/apps/weebrix/parts/ContactForm"
// import Features from "@jsroot/apps/weebrix/parts/Features"
// import PricingTable from "@jsroot/apps/weebrix/parts/PricingTable"
import PricingTable from "@jsroot/apps/weebrix/parts/PricingTable"
export default {
  components: {
    ContactForm,
    PricingTable,
    MainLanding,
    HalfShade,
    HalfShadeLeft
    // Features
  },
  data() {
    return {
      title: "Welcome",
      show: false,
      showText: false
    }
  },
  mounted: function() {
    this.show = true
    var that = this
    setTimeout(function() {
      that.showText = true
    }, 100)
  },
  methods: {
    runAddPage() {
      let apiUrl = "/api_public/v1/booster_pages/new"
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
  }
}
</script>
<style>
.darky-bg {
  background-image: linear-gradient(
    to top,
    rgba(23, 24, 32, 0.75),
    rgba(23, 24, 32, 0.75)
  );
  background-image: -moz-linear-gradient(
    to top,
    rgba(23, 24, 32, 0.65),
    rgba(23, 24, 32, 0.65)
  );
  background-image: -webkit-linear-gradient(
    to top,
    rgba(23, 24, 32, 0.65),
    rgba(23, 24, 32, 0.65)
  );
  background-image: -ms-linear-gradient(
    to top,
    rgba(23, 24, 32, 0.65),
    rgba(23, 24, 32, 0.65)
  );
  /* background-image: linear-gradient(
      top,
      rgba(23, 24, 32, 0.95),
      rgba(23, 24, 32, 0.95)
    ); */
  content: "";
  /* display: block; */
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 72px
  );
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>